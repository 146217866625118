class DivisionSlider {
    constructor(config) {
        this.slider = config.slider;
        this.tabs = this.slider.querySelector(config.tabs);
        this.cards = this.slider.querySelector(config.cards);
        this.cardsWrap = this.slider.querySelector(config.cardsWrap);
        this.dots = this.slider.querySelector(config.dots);
        this.docs = this.slider.querySelector(config.docs);

        this.tab = this.tabs.querySelectorAll(config.tab);
        this.card = this.cards.querySelectorAll(config.card);
        this.doc = this.docs.querySelectorAll(config.doc);
        this.dot = config.dot;
        this.dotsArr = null;
        
        this.activeSlide = 0;
        this.windowWidth = 0;
    }

    renderDots() {
        const dot = this.dots.querySelector(this.dot).cloneNode();
        this.dots.innerHTML = '';
        
        if (this.card.length < 2) return; 

        Array.from(this.card).forEach((item, index) => {
            const clone = dot.cloneNode();
            clone.addEventListener('click', ()=> this.handlerSelectSlider(index));
            this.dots.append(clone);
        })

        this.dotsArr = this.dots.querySelectorAll(this.dot);
    }

    changeElem(arr) {
        arr.forEach(item => item.classList.remove('active-slide'));
        arr[this.activeSlide].classList.add('active-slide');
    }

    changeCard() {
        this.windowWidth > 767 ? this.changeCardDesc() : this.changeCardMobile();
    }
    
    changeCardDesc() {
        let baforeInd = Array.from(this.card).slice(0, this.activeSlide).reverse();
        let currInd = Array.from(this.card).slice(this.activeSlide, this.activeSlide + 1);
        let afterInd = Array.from(this.card).slice(this.activeSlide + 1);
        const cardWidth = currInd[0].offsetWidth;
        const prc = cardWidth / 10 / 2.5;

        if (baforeInd.length) {
            baforeInd.forEach((item, index) => {
                item.style.zIndex = this.activeSlide - index + 1;
                item.style.transform = `translate3d(-${(index +1) * prc}px, 0px, -${(index +1) * prc}px) rotateZ(-${(index + 1) * 1}deg) scale(.${100 - ((index + 1) * 6)})`;
            })
        }

        if (afterInd.length) {
            afterInd.forEach((item, index) => {
                item.style.zIndex = this.activeSlide + (afterInd.length - index) ;
                item.style.transform = `translate3d(${(index + 1) * prc}px, 0px, -${(index +1) * prc}px) rotateZ(${(index + 1) * 1}deg) scale(.${100 - ((index + 1) * 6)})`;
            })
        }

        currInd.forEach(item => {
            item.style.zIndex = this.card.length + 1;
            item.style.transform = `translate3d(0, 0, 0) rotateZ(0) scaleZ(1)`;
        })
    }

    changeCardMobile() {
        this.cardsWrap.style.transform = `translateX(-${this.activeSlide * 100}%)`;
    }

    handlerSelectSlider(index) {
        if (index === this.activeSlide) return;

        this.activeSlide = index;

        this.handlerChangeSlide();
    }

    handlerChangeSlide() {
        this.changeElem(this.tab);
        this.changeElem(this.dotsArr);
        this.changeElem(this.card);
        this.changeElem(this.doc);
        this.changeCard();
    }

    resetCardsStyle() {
        this.card.forEach(item => item.style = '');
        this.cardsWrap.style = '';
    }

    evSliderTransition(block) {
        block.forEach((item, index) => item.addEventListener('click', ()=> this.handlerSelectSlider(index)));
    }

    evTouchMove() {
        let touchPosStart = 0;

        this.cardsWrap.addEventListener('touchstart', (ev) => {
            touchPosStart = ev.touches[0].clientX;
        }, {passive: true, capture: false})

        let timerProjects;
        this.cardsWrap.addEventListener('touchmove', (ev) => {

            clearTimeout(timerProjects);

            if (Math.abs(ev.touches[0].clientX - touchPosStart) < this.windowWidth / 5) return;

            let index = ev.touches[0].clientX < touchPosStart ? this.activeSlide + 1 : this.activeSlide - 1;

            if (index < 0 || index > this.card.length - 1) return;

            timerProjects = setTimeout(() => {
                this.handlerSelectSlider(index);
            }, 50);
        }, {passive: true, capture: false})
    }

    init() {
        if (this.card.length < 2) return;

        this.windowWidth = window.innerWidth;

        this.renderDots();
        this.evSliderTransition(this.tab);
        this.handlerChangeSlide();
        this.evTouchMove();

        window.addEventListener('resize', ()=> {
            this.windowWidth = window.innerWidth;
            this.resetCardsStyle();
            this.handlerChangeSlide();
        })
    }
}

(function initDivisionSlider () {
    const slider = document.querySelector('.division-slider');

    if (!slider) return;

    new DivisionSlider({
        slider: slider,
        tabs: '.division-slider-tabs',
        cards: '.division-slider-cards',
        cardsWrap: '.division-slider-cards-wrap',
        dots: '.division-slider-dots',
        docs: '.division-slider-docs',
        tab: '.division-slider-tabs__item',
        card: '.division-slider-card',
        dot: '.division-slider-dots__item',
        doc: '.division-slider-docs__item',
    }).init();
})();

// initDivisionSlider();