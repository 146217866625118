window.addEventListener('DOMContentLoaded', () => {
	if ($(".agancy").length) {
		document.querySelectorAll('.tab_agancy').forEach((e) => {
			let tabTabs = e.querySelectorAll('.tab__menu_laptop .tab__item');
			let tabItems = e.querySelectorAll('.tab__body .tab__content');
			for(let i =0;i<tabTabs.length;i++) {
				tabTabs[0].click();
				tabTabs[i].onclick = () => {
				tabTabs.forEach((e)  => { e.classList.remove('tab__active--agancy') }); 
				tabItems.forEach((e)  => { e.classList.remove('tab__content_active') });
				tabTabs[i].classList.add('tab__active--agancy');
				tabItems[i].classList.add('tab__content_active');
			}
			}
		});
	}
});

//tab 

const tabs = (headerSelector, tabSelector, contentSelector, activeClass) => {

	const header = document.querySelector(headerSelector),
		  tab = Array.from(document.querySelectorAll(tabSelector)),
		  content = Array.from(document.querySelectorAll(contentSelector));

	if(!content.length || !tab.length) return;
  
	function hideTabContent() {
		content.forEach(item => {
			item.style.overflow = 'hidden';
			item.style.transition = '2s';
			item.style.opacity = '0';
			item.style.height = '0';
		});
  
		tab.forEach(item => {
			item.classList.remove(activeClass);
		});
	}
  
	function showTabContent(i = 0) {	
		//content[i].style.display = 'block';
		content[i].style.opacity = '1';
		content[i].style.overflow = 'visible';
		content[i].style.transition = 'all .5s';
		content[i].style.height = 'auto';
		//content[i].classList.add('active-content');
		tab[i].classList.add(activeClass);
	}
  
	hideTabContent();
	showTabContent();
  
	header.addEventListener('click', (e) => {
		const target = e.target;
		if (target &&
			(target.classList.contains(tabSelector.replace(/\./, "")) || 
		target.parentNode.classList.contains(tabSelector.replace(/\./, "")))) {
			tab.forEach((item, i) => {
				if (target == item || target.parentNode == item) {
					hideTabContent();
					showTabContent(i);
				}
			});
		}
	});
  };
  
  export default tabs;
  

window.addEventListener('DOMContentLoaded', () => {
	tabs('.tab--js', '.case__btn','.case__group', 'case__active');
	tabs('.tab--js', '.case__btn','.case__scrollbar_tab', 'case__active_scroll');
});


window.addEventListener('DOMContentLoaded', () => {
	if ($(".chapter").length) {
	class Tabs { 
		constructor(config) { 
		  this.tabs = config.tabsSelector
		  this.head = config.tabsHeadSelector
		  this.body = config.tabsBodySelector 
		  this.caption = config.tabsCaptionSelector 
		  this.captionActiveClass = config.tabsCaptionActiveClass 
		  this.contentActiveClass = config.tabsContentActiveClass
		}
	
		getActiveTabName(head) { 
		  return head.querySelector(`.${this.captionActiveClass}`).dataset.tab 
		}
	
		setActiveContent(head, body) { 
		  if (body.querySelector(`.${this.contentActiveClass}`)) { 
			body.querySelector(`.${this.contentActiveClass}`).classList.remove(this.contentActiveClass) 
		  }
		  body.querySelector(`[data-tab=${this.getActiveTabName(head)}]`).classList.add(this.contentActiveClass) 
		}
	
		onLoad(head, body) { 
		 
		  if (!head.querySelector(`.${this.captionActiveClass}`)) {
			head.querySelector(this.caption).classList.add(this.captionActiveClass) 
		  }
	
		  this.setActiveContent(head, body)
		}
	
		onClick(head, body) { 
		  head.addEventListener('click', e => { 
			const caption = e.target.closest(this.caption) 
			if (!caption) return 
			if (caption.classList.contains(this.captionActiveClass)) return 
	
			if (head.querySelector(`.${this.captionActiveClass}`)) { 
			  head.querySelector(`.${this.captionActiveClass}`).classList.remove(this.captionActiveClass) 
			}
	
			caption.classList.add(this.captionActiveClass) 
	
			this.setActiveContent(head, body) 
		  })
		}
	
		init() {
		  const tabs = document.querySelector(this.tabs)
	
		  if (!tabs) return;
		  
		  const head = tabs.querySelector(this.head) 
		  const body = tabs.querySelector(this.body)
	
		  this.onLoad(head, body)
	
		  this.onClick(head, body)
		}
	  }
	
	  new Tabs({
		tabsSelector: '.tab_chapter',
		tabsHeadSelector: '.tab__head',
		tabsBodySelector: '.tab__body',
		tabsCaptionSelector: '.tab__item',
		tabsCaptionActiveClass: 'tab__item_active',
		tabsContentActiveClass: 'tab__content_active',
		
	  }).init()
	}
});